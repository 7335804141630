import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Button } from './layout/StyledComponents';
// import { Link } from 'gatsby';
// import { StaticImage } from 'gatsby-plugin-image';
import { colors, device, fonts } from './layout/GlobalStyles';
import { details } from '../details';
import map from '../images/svgs/marker-blue.svg';
import phone from '../images/svgs/phone-blue.svg';

const StyledVisitOurPlace = styled.div`
    margin-top: 72px;

    iframe {
        border-radius: 15px;
        width: 343px;
        margin: 32px 0px;
        height: 207.37px;
    }
    h2 {
        margin: 0;
    }
    .hours-container {
        margin: 0;
        display: flex;
        justify-content: space-between;
        ul {
            font-size: 16px;
            line-height: 161.5%;
            li {
                margin: 16px 0px;
            }
            list-style: none;
        }
        ul:first-of-type {
            font-weight: 700;
            text-align: left;
        }
        ul:last-of-type {
            text-align: right;
        }
    }
    .details-container {
        margin: 32px 0 54px 0;
    }
    @media ${device.tablet} {
        iframe {
            min-width: 100%;
            height: 400px;
            margin: 32px auto 72px;
        }
    }
    @media ${device.laptop} {
        padding: 0px 0 72px;
        .flex-container {
            display: flex;
            align-items: start;
            max-width: 1080px;
            justify-content: space-between;
            iframe {
                min-width: 600px;
                height: 325px;
                margin: 0px 0 72px;
            }
        }
        .hours-container {
            position: relative;
            bottom: 15px;
            max-width: 300px;
        }
    }
    @media ${device.laptopL} {
        .flex-container {
            max-width: 1280px;
            iframe {
                min-width: 700px;
                height: 330px;
                margin: 0px 0 72px;
            }
        }
    }
`;

const Form = styled.form`
    border: 1px solid #b1e3ff;
    box-shadow: 0px 64px 116px rgba(33, 152, 183, 0.1),
        0px 22.2412px 47.9081px rgba(33, 152, 183, 0.0518691),
        0px 8.37958px 21.9482px rgba(33, 152, 183, 0.062257),
        0px 2.91633px 8.3545px rgba(33, 152, 183, 0.0504303);
    border-radius: 15px;
    display: flex;
    padding: 15px;
    flex-direction: column;
    align-items: center;
    margin-bottom: 54px;
    p {
        font-weight: 600;
        margin: 16px 0 32px 0px;
        font-size: 20px;
        line-height: 132.5%;
    }
    input,
    textarea {
        outline: 1px solid ${colors.blue1};
        border-radius: 5px;
    }
    textarea {
        color: ${colors.black};
        opacity: 0.8;
        height: 90px;
        margin-top: 10px;
        padding: 12px;
        border-top: 0px;
        border-left: 0px;
        margin-bottom: 15px;
        border-right: 0px;
    }
    textarea,
    input {
        border: 1px solid ${colors.blue1};
        transition: 0.2s ease-in;
    }
    input[type='text']::placeholder {
        font-family: ${fonts.primary};
    }
    input[type='text']:focus,
    input[type='date']:focus,
    input[type='email']:focus,
    input[type='tel']:focus,
    textarea,
    select {
    }
    select {
    }
    textarea:focus::placeholder {
        color: ${colors.blue1};
    }
    input:focus,
    select:focus,
    textarea:focus {
        outline: 1px solid ${colors.blue2};
    }
    label {
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
        align-items: start;
        textarea {
            margin-top: 5px;
            width: 270px;
        }
        input {
            padding-left: 10px;
            margin-top: 5px;
            width: 288px;
            height: 40px;
        }
    }
    @media ${device.tablet} {
        width: 350px;
        margin: 0 auto 62px;
    }
    @media ${device.laptop} {
        width: 350px;
        height: 640px;
        margin: 0 auto;
    }
`;

const Detail = styled.div`
    display: flex;
    align-items: center;
    max-height: 70px;
    margin: 15px 0px;
    p {
        text-decoration: underline;
        font-weight: 400;
        font-size: 18px;
        line-height: 161%;
    }
    div {
        margin-right: 24px;
        background: ${colors.blue1};
        min-width: 25px;
        min-height: 25px;
        padding: 15px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const VisitOurPlace: React.FC = () => {
    const [submitState, setSubmitState] = useState<string>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit = async (e: any) => {
        e.preventDefault();
        setSubmitState('submitting');

        const formData = new FormData(e.target);
        const data = [...formData.keys()].reduce((obj, key) => {
            const value = formData.getAll(key).join(', ').trim();
            if (value) {
                obj[key] = value;
            }
            return obj;
        }, {} as Record<string, unknown>);

        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        setSubmitState('submitted');

        e.target.reset();
    };
    return (
        <StyledVisitOurPlace className="container">
            <h2>Visit our place</h2>
            <div className="details-container">
                <a
                    style={{ color: `${colors.blue2}` }}
                    rel="noopener noreferrer"
                    target="__blank"
                    href={details.addressUrl}
                >
                    <Detail>
                        <div>
                            <img src={map} alt="green map marler" />
                        </div>

                        <p>{details.address}</p>
                    </Detail>
                </a>
                <a href={`${details.phoneUrl}`}>
                    <Detail>
                        <div>
                            <img src={phone} alt="green map marler" />
                        </div>
                        <p>{details.phone}</p>
                    </Detail>
                </a>
            </div>

            <div className="flex-container">
                <div>
                    <div className="hours-container">
                        <ul>
                            <li>Monday</li>
                            <li>Tuesday</li>
                            <li>Wednesday</li>
                            <li>Thursday</li>
                            <li style={{ color: `${colors.gray4}` }}>Friday</li>
                            <li style={{ color: `${colors.gray4}` }}>Saturday</li>
                            <li style={{ color: `${colors.gray4}` }}>Sunday</li>
                        </ul>
                        <ul>
                            <li>8:30 AM - 5 PM</li>
                            <li>8:30 AM - 5 PM</li>
                            <li>8:30 AM - 5 PM</li>
                            <li>8:30 AM - 5 PM</li>
                            <li style={{ color: `${colors.gray4}` }}>Closed</li>
                            <li style={{ color: `${colors.gray4}` }}>Closed</li>
                            <li style={{ color: `${colors.gray4}` }}>Closed</li>
                        </ul>
                    </div>
                    <iframe
                        className="image-shadow"
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12159.700780124143!2d-86.863788!3d40.366183!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8ec0fd45969d1579!2sInnovative%20Medicine!5e0!3m2!1sen!2sus!4v1662494737060!5m2!1sen!2sus="
                        width="100%"
                        loading="lazy"
                        title="Friedler Dental"
                        height="100%"
                        frameBorder="0"
                        style={{ border: 0 }}
                        aria-hidden="false"
                    />
                </div>
                <div id="contact"></div>
                <Form onSubmit={onSubmit} name="contact">
                    <p>Request More Information</p>
                    <div className="input-wrap">
                        <label htmlFor="full-name">
                            Full Name
                            <input
                                name="full-name"
                                required
                                type="text"
                                id="first-name"
                                placeholder="John Doe"
                            />
                        </label>
                        <label htmlFor="email">
                            Email
                            <input
                                name="email"
                                type="text"
                                required
                                id="email"
                                placeholder="Email"
                            />
                        </label>

                        <label htmlFor="phone">
                            Phone Number
                            <input
                                type="tel"
                                name="phone"
                                required
                                id="phone"
                                placeholder="(000) 000-0000"
                            />
                        </label>
                        <label htmlFor="notes">
                            How might we help you?
                            <textarea id="notes" name="notes" />
                        </label>
                    </div>
                    {submitState === 'submitted' ? (
                        <>
                            <h3>
                                Message sent! Somebody from our office will be in touch shortly.
                            </h3>
                        </>
                    ) : (
                        <Button disabled={submitState === 'submitting'} button="secondary" fill>
                            {submitState === 'submitting' ? 'Sending message...' : 'Send Request'}
                        </Button>
                    )}
                </Form>
            </div>
        </StyledVisitOurPlace>
    );
};

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-intake-forms-tsx": () => import("./../../../src/pages/about-us/intake-forms.tsx" /* webpackChunkName: "component---src-pages-about-us-intake-forms-tsx" */),
  "component---src-pages-about-us-meet-our-team-tsx": () => import("./../../../src/pages/about-us/meet-our-team.tsx" /* webpackChunkName: "component---src-pages-about-us-meet-our-team-tsx" */),
  "component---src-pages-about-us-testimonials-tsx": () => import("./../../../src/pages/about-us/testimonials.tsx" /* webpackChunkName: "component---src-pages-about-us-testimonials-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-conditions-tsx": () => import("./../../../src/pages/conditions.tsx" /* webpackChunkName: "component---src-pages-conditions-tsx" */),
  "component---src-pages-counseling-tsx": () => import("./../../../src/pages/counseling.tsx" /* webpackChunkName: "component---src-pages-counseling-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-services-tsx": () => import("./../../../src/pages/our-services.tsx" /* webpackChunkName: "component---src-pages-our-services-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */),
  "component---src-pages-test-tsx": () => import("./../../../src/pages/test.tsx" /* webpackChunkName: "component---src-pages-test-tsx" */),
  "component---src-pages-therapies-tsx": () => import("./../../../src/pages/therapies.tsx" /* webpackChunkName: "component---src-pages-therapies-tsx" */),
  "component---src-templates-blog-details-js": () => import("./../../../src/templates/blog-details.js" /* webpackChunkName: "component---src-templates-blog-details-js" */),
  "component---src-templates-conditions-details-js": () => import("./../../../src/templates/conditions-details.js" /* webpackChunkName: "component---src-templates-conditions-details-js" */),
  "component---src-templates-service-details-js": () => import("./../../../src/templates/service-details.js" /* webpackChunkName: "component---src-templates-service-details-js" */),
  "component---src-templates-therapies-details-js": () => import("./../../../src/templates/therapies-details.js" /* webpackChunkName: "component---src-templates-therapies-details-js" */)
}


import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { colors, device, fonts } from './layout/GlobalStyles';
import { details } from '../details';
import map from '../images/svgs/marker-blue.svg';
import phone from '../images/svgs/phone-blue.svg';
import logo from '../images/svgs/logo-white.svg';
import facebook from '../images/svgs/facebook-white.svg';
import twitter from '../images/svgs/twitter-white.svg';
import linkedin from '../images/svgs/linkedin-white.svg';

const StyledFooter = styled.div`
    background: ${colors.gray5};
    padding: 64px 0px 0px;

    @media ${device.laptop} {
        .flex-container {
            display: flex;
            align-items: center;
        }
    }
`;

const Logo = styled.div`
    margin: 0 0 50px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
        width: 160px;
        height: auto;
    }
    @media ${device.laptop} {
        img {
            width: 380px;
            position: absolute;
            left: 170px;
        }
    }
`;

const Detail = styled.div`
    display: flex;
    align-items: center;
    margin-top: 30px;
    button {
        background: ${colors.blue1};
        border: none;
        padding: 12px;
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
        margin-right: 20px;
    }
    p {
        font-style: normal;
        font-weight: 400;
        text-decoration: underline;
        font-size: 16px;
        color: ${colors.white};
    }
    a {
    }
    img {
    }
`;

const Navigation = styled.div`
    .list-container {
        display: flex;
        justify-content: space-between;
    }
    h6 {
        font-weight: 600;
        font-size: 20px;
        line-height: 132.5%;
        font-family: ${fonts.primary};
        color: ${colors.white};
        margin: 52px 0px 0px 0px;
    }
    ul {
        margin: 0;
        list-style: none;
        padding-left: 0;
        li,
        a {
            margin-top: 32px;
            color: ${colors.white};
        }
    }

    @media ${device.laptop} {
        .list-container {
            display: flex;
            justify-content: space-between;
            ul:last-of-type {
                position: relative;
                left: 70px;
            }
        }
        h6 {
            margin: 0px 0px 0px 0px;
        }
    }
`;

const Social = styled.div`
    margin: 0 20px 48px;
    .social-container {
        display: flex;
        width: 250px;
        margin: 0 auto;
        margin-top: 32px;
        justify-content: space-between;
    }
    > p {
        margin-top: 52px;

        text-align: center;
        color: ${colors.white};
    }
    @media ${device.laptop} {
        display: flex;
        align-items: baseline;
        position: relative;
        right: 330px;
        .social-container {
            display: flex;
            margin-top: 32px;
            width: 160px;
            justify-content: space-between;
        }
        width: 414px;
        margin: 0 auto 108px;
    }
`;

const BottomPanel = styled.div`
    background: rgba(252, 252, 252, 0.2);
    padding: 15px 0px;
    margin: 56px 0 0 0;
    p {
        color: ${colors.gray4};
        text-align: center;
    }
`;

const getCurrentYear = () => {
    const now = new Date();
    return now.getFullYear();
};

const currentYear = getCurrentYear();

export const Footer: React.FC = () => {
    return (
        <StyledFooter>
            <Logo>
                <img src={logo} alt="logo" />
            </Logo>
            <div className="flex-container">
                <div className="container details-container">
                    <a
                        style={{ color: `${colors.blue2}` }}
                        rel="noopener noreferrer"
                        target="__blank"
                        href={details.addressUrl}
                    >
                        <Detail>
                            <button>
                                <img src={map} alt="green map marler" />
                            </button>
                            <p>{details.address}</p>
                        </Detail>
                    </a>
                    <a
                        style={{ color: `${colors.white}` }}
                        rel="noopener noreferrer"
                        href={details.phoneUrl}
                    >
                        <Detail>
                            <button>
                                <img src={phone} alt="phone symbol" />
                            </button>
                            <p>{details.phone}</p>
                        </Detail>
                    </a>
                </div>
                <Navigation className="container">
                    <h6>Navigation</h6>
                    <div className="list-container">
                        <ul>
                            <li>
                                <Link to="/about-us/">About Us </Link>
                            </li>
                            <li>
                                <Link to="/pain/">Pain</Link>
                            </li>
                            <li>
                                <Link to="/services/treatments/testosterone/">Testosterone</Link>
                            </li>
                            <li>
                                <Link to="/services/treatments/female-hormones/">
                                    Female Hormones
                                </Link>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <Link to="/services/treatments/weight-loss/">Weight Loss</Link>
                            </li>
                            <li>
                                <Link to="/services/treatments/energy-get-more-of-it/">
                                    Energy Boost
                                </Link>
                            </li>
                            {/* <li>
                                <Link to="/services/treatments/sleep/">Sleep Treatment</Link>
                            </li> */}
                            <li>
                                <Link to="/terms-of-service/">Terms of Service</Link>
                            </li>
                        </ul>
                    </div>
                </Navigation>
            </div>
            <Social>
                <p>Follow us</p>
                <div className="social-container">
                    <a rel="noopener noreferrer" target="__blank" href={details.facebookUrl}>
                        <img src={facebook} alt="facebook logo" />
                    </a>
                    <a rel="noopener noreferrer" target="__blank" href={details.twitterUrl}>
                        <img src={twitter} alt="twitter logo" />
                    </a>
                    <a rel="noopener noreferrer" target="__blank" href={details.linkedinUrl}>
                        <img src={linkedin} alt="linkedin logo" />
                    </a>
                </div>
            </Social>
            <BottomPanel>
                <p>©{currentYear} INNOVATIVE MEDICINE</p>
            </BottomPanel>
        </StyledFooter>
    );
};

import { Global, css } from '@emotion/react';
import React from 'react';

export const colors = {
    blue1: '#B1E3FF',
    blue2: '#0597E9',
    blue3: '#0E79B5',
    gray4: '#95A0A9',
    gray5: '#313233',
    white: '#fcfcfc',
    black: '#000000',
};
export const fonts = {
    primary: 'Poppins',
    secondary: 'Montserrat',
};

const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '400px',
    tablet: '630px',
    laptop: '1030px',
    laptopL: '1360px',
    desktop: '1580px',
    desktopL: '1820px',
};

export const device = {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopL: `(min-width: ${size.desktopL})`,
};

export const GlobalStyles = () => {
    return (
        <Global
            styles={css`
                margin-top: 1000px;
                font-family: 'Poppins';
                .shadow {
                    box-shadow: 0px 64px 116px rgba(33, 152, 183, 0.1),
                        0px 22.2412px 47.9081px rgba(33, 152, 183, 0.0518691),
                        0px 8.37958px 21.9482px rgba(33, 152, 183, 0.062257),
                        0px 2.91633px 8.3545px rgba(33, 152, 183, 0.0504303);
                }
                input {
                    border: none;
                    border-radius: none;
                }
                html {
                    box-sizing: border-box;
                    font-size: 100%;

                    overflow-x: hidden;
                    height: -webkit-fill-available;
                    button {
                        cursor: pointer;
                    }
                }
                body {
                    overflow-x: hidden;
                    font-family: 'Lato';
                    min-height: 100vh; /* fall-back */
                    min-height: -moz-available;
                    min-height: -webkit-fill-available;
                    min-height: fill-available;
                    margin: 0em;
                    -ms-text-size-adjust: 100%;
                    -webkit-text-size-adjust: 100%;
                    scroll-behavior: smooth;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    min-height: 100vh;
                    /* mobile viewport bug fix */
                    min-height: -webkit-fill-available;
                }
                a {
                    text-decoration: none;
                }
                ul {
                    padding: 0;
                }

                ::-webkit-calendar-picker-indicator {
                    width: 18px;
                    height: 18px;
                }
                input,
                textarea {
                    outline: 1px solid ${colors.white};
                }
                ::-webkit-calendar-picker-indicator:focus {
                    background: green;
                }

                textarea:focus {
                    outline: 1px solid ${colors.blue2}; /* oranges! yey */
                }
                input:focus {
                    outline: 1px solid ${colors.blue2}; /* oranges! yey */
                }
                textarea {
                }
                span,
                label {
                    font-family: ${fonts.primary};
                }
                a {
                    font-family: ${fonts.primary};
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 161.5%;
                }
                p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 161.5%;
                    color: ${colors.gray5};
                    span {
                        font-weight: 800;
                    }
                }
                h1,
                h2,
                h3,
                h4,
                h5,
                li,
                p {
                    color: ${colors.gray5};
                }
                h1 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 32px;
                    line-height: 48px;
                }
                h2 {
                    font-weight: 600;
                    font-size: 28px;
                    line-height: 42px;
                }
                h3 {
                    font-family: '';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 132.5%;
                }
                h1,
                h2,
                h3,
                h4,
                h5,
                p,
                li {
                    color: ${colors.black};
                    font-family: ${fonts.primary};
                }
                .image-shadow {
                    box-shadow: rgba(100, 100, 111, 0.7) 0px 7px 29px 0px;
                }
                .container {
                    max-width: 340px;
                    margin: 0 auto;
                }
                .small-container {
                    max-width: 340px;
                    margin: 0 auto;
                }
                @media ${device.mobileL} {
                    .container {
                        max-width: 370px;
                        margin: 0 auto;
                    }
                    .small-container {
                        max-width: 370px;
                        margin: 0 auto;
                    }
                }
                @media ${device.tablet} {
                    .center {
                        display: flex;
                        justify-content: center;
                    }
                    h1 {
                        font-size: 36px;
                        line-height: 54px;
                    }
                    h2 {
                        font-size: 36px;
                        line-height: 54px;
                    }
                    .small-container {
                        max-width: 600px;
                        margin: 0 auto;
                    }
                    .container {
                        margin: 0 auto;
                        max-width: 690px;
                    }
                }
                @media ${device.laptop} {
                    h1 {
                        font-size: 40px;
                        line-height: 60px;
                    }
                    .small-container {
                        max-width: 996px;
                        margin: 0 auto;
                    }
                    h2 {
                        font-size: 44px;
                        line-height: 66px;
                    }
                    p {
                        font-size: 18px;
                        line-height: 161.5%;
                    }
                    .container {
                        margin: 0 auto;
                        max-width: 1060px;
                    }
                }
                @media ${device.laptopL} {
                    .container {
                        margin: 0 auto;
                        max-width: 1200px;
                    }
                }
                @media ${device.desktop} {
                }
            `}
        />
    );
};

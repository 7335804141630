/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { colors, device } from '../layout/GlobalStyles';
import { navMenu, mobileNavMenu } from './navMenu';
import logo from '../../images/svgs/logo.svg';
import phoneBlue from '../../images/svgs/phone-blue.svg';
import phoneWhite from '../../images/svgs/phone-white.svg';
import arrowDown from '../../images/svgs/arrow-down-black.svg';
import { details } from '../../details';
import { Button } from '../layout/StyledComponents';
import { Link } from 'gatsby';

const StyledNav = styled.div``;

const MobileNav = styled.div<Props>`
    top: 0;
    background: ${colors.white};
    z-index: 2;
    position: fixed;
    width: 100vw;
    overflow-x: hidden;
    .mobile-nav-container {
        display: flex;
        padding-top: 8px;
        justify-content: space-between;
        align-items: center;
        img {
            padding: 16px;
        }
    }

    .button-container {
        display: flex;
        align-items: center;
        img {
            margin-right: 32px;
        }
    }
    @media ${device.laptop} {
        display: none;
    }
`;

const Burger = styled.button<Props>`
    padding-right: 16px;
    border: none;
    background: none;
    div {
        width: 20px;
        margin: 4px 0px;
        height: 2px;
        background: ${colors.gray5};
    }
`;

const MobileMenu = styled.div<Props>`
    height: 100vh;
    width: 100vw;
    background: ${colors.white};
    top: 0;
    z-index: 1;
    left: 0;
    display: flex;
    transition: transform 0.2s cubic-bezier(0.33, 1, 0.68, 1);
    transform: ${({ active }) => (active ? 'translateX(0%)' : 'translateX(100%)')};
    position: fixed;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .scroll-container {
        overflow-x: scroll;
        height: 630px;
        margin-top: 245px;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    ul {
        z-index: 4;
        right: 16px;
        position: relative;
        bottom: 64px;
        text-align: left;
        list-style: none;
    }
    li {
        color: ${colors.blue2};
        margin: 32px 0px;
        font-weight: 600;
        color: ${colors.blue3};
    }
    @media ${device.laptop} {
        display: none;
    }
`;

interface Navprops {
    mobileScroll: boolean;
}

const DesktopNav = styled.div<Navprops>`
    background: #fcfcfc;
    display: flex;
    justify-content: center;
    .desktop-nav-container {
        max-width: 1246px;
        margin: 0 32px;
    }
    .hover-underline-animation {
        display: inline-block;
        position: relative;
    }
    .hover-underline-animation:hover:after {
        transform: scaleX(1);

        transform-origin: left right;
    }
    .hover-underline-animation:after {
        content: '';
        position: absolute;
        width: 50%;
        transform: scaleX(0);
        height: 2px;
        bottom: -3px;
        left: 25%;
        background-color: ${colors.blue2};
        transform-origin: left right;
        transition: transform 0.25s cubic-bezier(0.33, 1, 0.68, 1);
    }
    .desktop-logo {
        width: 250px;
    }
    .top-flex {
        display: flex;
        margin-top: 30px;
        justify-content: space-between;
    }
    .menu-flex {
        display: none;
        box-shadow: 0px 64px 116px rgba(33, 152, 183, 0.1),
            0px 22.2412px 47.9081px rgba(33, 152, 183, 0.0518691),
            0px 8.37958px 21.9482px rgba(33, 152, 183, 0.062257),
            0px 2.91633px 8.3545px rgba(33, 152, 183, 0.0504303);
        border-radius: 15px;
        justify-content: space-between;
        ul {
            padding: 0 0px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            li {
                margin: 16px 48px;
            }
        }
    }

    .button-container {
        list-style: none;
        gap: 16px;
        display: none;
        align-items: center;
        width: 470px;
        .phone-icon {
            transition: background-color 0.2s cubic-bezier(0.33, 1, 0.68, 1);
            background: ${colors.blue2};
            padding: 19px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            &:hover {
                background: #0e79b5;
            }
        }
        .first-button {
            width: 193px;
        }
        .second-button {
            width: 153px;
        }
    }
    @media ${device.laptop} {
        .button-container {
            display: flex;
        }
        .menu-flex {
            padding: 0 30px;
            margin: 32px 16px 0;
            display: flex;
        }
        display: flex;
    }
    @media ${device.laptopL} {
        .menu-flex {
            margin: 32px 32px 0;
            padding: 0 00px;
            ul {
                li {
                    margin: 16px 24px;
                }
            }
        }
    }
`;

const DropDownMenu = styled.div`
    position: absolute;
    z-index: 100;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 15px 0px;
    top: 55px;

    /* flex-wrap: wrap; */
    max-height: 300px;
    justify-content: space-between;
    flex-wrap: wrap;
    /* width: 200px; */
    left: -5em;
    background: ${colors.white};
    box-shadow: 0px 64px 116px rgba(33, 152, 183, 0.1),
        0px 22.2412px 47.9081px rgba(33, 152, 183, 0.0518691),
        0px 8.37958px 21.9482px rgba(33, 152, 183, 0.062257),
        0px 2.91633px 8.3545px rgba(33, 152, 183, 0.0504303);
    li {
        list-style: none;
        width: 230px;
        padding: 8px 0px 8px 38px;
        &:hover {
            background: rgba(112, 201, 251, 0.2);
        }
    }
    @media ${device.laptop} {
    }
`;

interface Props {
    active: boolean;
    setActive: boolean;
}

const debounce = (func, ms) => {
    let timer;
    return (...args) => {
        if (!timer) {
            timer = setTimeout(() => {
                timer = undefined;
                func.apply(this, args);
            }, ms);
        }
    };
};

export const Nav: React.FC = ({ active, setActive }) => {
    const menuRef = useRef();
    const [menu, setMenu] = useState<string>('');
    const [mobileMenu, setMobileMenu] = useState<string>('');
    const isSSR = typeof window === 'undefined';
    const [position, setPosition] = useState(isSSR ? 0 : window.scrollY);
    const [scroll, setScroll] = useState(false);
    const debounceMs = 200;

    const onScroll = useCallback(
        debounce(() => setPosition(window.scrollY), 300),
        [debounceMs, setPosition]
    );

    useEffect(() => {
        if (isSSR) return;
        window.addEventListener('scroll', onScroll);
        if (position > 300) {
            setScroll(true);
        } else {
            setScroll(false);
        }
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, [position]);

    return (
        <StyledNav>
            <MobileNav active={active}>
                <div className="mobile-nav-container">
                    <Link to="/">
                        <button
                            onClick={() => {
                                setActive(false);
                                setMobileMenu('');
                            }}
                            style={{ background: 'none', border: 'none' }}
                        >
                            <img src={logo} alt="Innovative Medicine Logo" />
                        </button>
                    </Link>
                    <div className="button-container">
                        <a rel="noopener noreferrer" href={`${details.phoneUrl}`}>
                            <img src={phoneBlue} alt="Call us at (765) 471-1100" />
                        </a>

                        <Burger
                            active={active}
                            onClick={() => {
                                setActive(active => !active);
                                setMobileMenu('');
                            }}
                        >
                            <div className="line-one"></div>
                            <div className="line-two"></div>
                            <div className="line-three"></div>
                        </Burger>
                    </div>
                </div>
            </MobileNav>
            <MobileMenu active={active}>
                <ul>
                    {mobileMenu === '' &&
                        mobileNavMenu.map(coreItems => {
                            if (coreItems.menu) {
                                return (
                                    <li
                                        onClick={() => setMobileMenu(coreItems.name)}
                                        key={coreItems.name}
                                    >
                                        <Link
                                            style={{ fontWeight: '700', color: `${colors.blue2}` }}
                                        >
                                            {coreItems.name}
                                        </Link>
                                    </li>
                                );
                            } else {
                                return (
                                    <li
                                        onClick={() => {
                                            setActive(false);
                                            setMenu('');
                                        }}
                                        key={coreItems.name}
                                    >
                                        <Link
                                            to={coreItems.path}
                                            style={{ fontWeight: '700', color: `${colors.blue2}` }}
                                        >
                                            {coreItems.name}
                                        </Link>
                                    </li>
                                );
                            }
                        })}
                    {mobileMenu === 'About Us' &&
                        mobileNavMenu[0].menu.map(coreItems => {
                            return (
                                <li
                                    onClick={() => {
                                        setActive(false);
                                        setMenu('');
                                    }}
                                    key={coreItems.name}
                                >
                                    <Link
                                        style={{ fontWeight: '700', color: `${colors.blue2}` }}
                                        to={coreItems.path}
                                    >
                                        {coreItems.name}
                                    </Link>
                                </li>
                            );
                        })}
                    {mobileMenu === 'Our Services' && (
                        <div className="scroll-container">
                            <p>
                                Scroll down
                                <img
                                    style={{ position: 'relative', left: '9px' }}
                                    src={arrowDown}
                                    alt="scroll down for services"
                                />
                            </p>
                            {mobileNavMenu[1].menu.map(coreItems => {
                                return (
                                    <li
                                        onClick={() => {
                                            setActive(false);
                                            setMenu('');
                                        }}
                                        key={coreItems.name}
                                    >
                                        <Link
                                            style={{ fontWeight: '700', color: `${colors.blue2}` }}
                                            to={coreItems.path}
                                        >
                                            {coreItems.name}
                                        </Link>
                                    </li>
                                );
                            })}
                        </div>
                    )}
                    {mobileMenu === 'Blog' &&
                        mobileNavMenu[4].menu.map(coreItems => {
                            return (
                                <li
                                    style={{ maxWidth: '180px' }}
                                    onClick={() => {
                                        setActive(false);
                                        setMenu('');
                                    }}
                                    key={coreItems.name}
                                >
                                    <Link
                                        style={{ fontWeight: '700', color: `${colors.blue2}` }}
                                        to={coreItems.path}
                                    >
                                        {coreItems.name}
                                    </Link>
                                </li>
                            );
                        })}
                </ul>

                <a
                    href="https://pay.balancecollect.com/m/innovativemedicine"
                    target="_blank"
                    rel="noopener nonreferrer noreferrer"
                >
                    <Button style={{ width: '150px' }} fill button="primary">
                        Pay Online
                    </Button>
                </a>
                {mobileMenu && (
                    <button
                        style={{
                            background: `${colors.blue1}`,
                            border: 'none',
                            borderRadius: '5px',
                            padding: '8px',
                            position: 'absolute',
                            top: '80px',
                            left: '22px',
                        }}
                        onClick={() => {
                            setMobileMenu('');
                        }}
                    >
                        <img
                            style={{ transform: 'rotate(90deg)', marginLeft: '0px' }}
                            src={arrowDown}
                            alt="open menu"
                        />{' '}
                        back
                    </button>
                )}
            </MobileMenu>
            <DesktopNav scroll={scroll}>
                <div className="desktop-nav-container">
                    <div className="top-flex">
                        <Link to="/">
                            <img
                                onClick={() => setMenu('')}
                                src={logo}
                                className="desktop-logo"
                                alt="Innovative Medicine Logo"
                            />
                        </Link>
                        <div className="button-container">
                            <Link to="#contact">
                                <Button className="first-button" button="secondary">
                                    Request Information
                                </Button>
                            </Link>
                            <a
                                href="https://pay.balancecollect.com/m/innovativemedicine"
                                target="_blank"
                                rel="noopener nonreferrer noreferrer"
                            >
                                <Button className="second-button" button="secondary">
                                    Pay Online
                                </Button>
                            </a>

                            <a className="phone-icon" href={`${details.phoneUrl}`}>
                                <img src={phoneWhite} alt="Call us at (765) 471-1100" />
                            </a>
                        </div>
                    </div>
                    <div className="menu-flex">
                        <ul>
                            {navMenu.map(coreItems =>
                                coreItems.menu ? (
                                    <li
                                        onMouseEnter={() => {
                                            if (!coreItems.menu) {
                                                setMenu('');
                                            } else {
                                                setMenu(coreItems.name);
                                            }

                                            setActive(true);
                                        }}
                                        className="hover-underline-animation"
                                        key={coreItems.name}
                                    >
                                        <Link
                                            style={{ color: `${colors.black}` }}
                                            to={coreItems.path}
                                        >
                                            <span>{coreItems.name}</span>
                                        </Link>
                                        <img
                                            style={{ marginLeft: '8px' }}
                                            src={arrowDown}
                                            alt="open menu"
                                        />
                                        {active && menu === coreItems.name && coreItems.menu && (
                                            <DropDownMenu
                                                name={menu}
                                                ref={menuRef}
                                                onMouseLeave={() => setMenu('')}
                                            >
                                                {coreItems.menu.map(subItems => {
                                                    return (
                                                        // eslint-disable-next-line react/jsx-key
                                                        <Link
                                                            key={subItems.name}
                                                            style={{
                                                                color: `${colors.black}`,
                                                            }}
                                                            to={subItems.path}
                                                        >
                                                            <li
                                                                onClick={() => {
                                                                    setMobileMenu('');
                                                                    setActive(false);
                                                                }}
                                                                style={{ margin: '0px 0px' }}
                                                            >
                                                                {subItems.name}
                                                            </li>
                                                        </Link>
                                                    );
                                                })}
                                            </DropDownMenu>
                                        )}
                                    </li>
                                ) : (
                                    <li className="hover-underline-animation" key={coreItems.name}>
                                        <Link
                                            onMouseEnter={() => setMenu('')}
                                            style={{ color: `${colors.black}` }}
                                            to={coreItems.path}
                                        >
                                            <span>{coreItems.name}</span>
                                        </Link>
                                    </li>
                                )
                            )}
                        </ul>
                    </div>
                </div>
            </DesktopNav>
        </StyledNav>
    );
};

import { Footer } from '../components/Footer';
import React, { useContext } from 'react';
import { GlobalStyles } from '../components/layout/GlobalStyles';
import { Nav } from '../components/nav/Nav';
import { VisitOurPlace } from '../components/Visit-Our-Place';
import { MDXProvider } from '@mdx-js/react';
import { BluePanels } from '../components/Blue-Panels';
import { ServiceTemplatePanel } from '../components//layout/Service-Template-Panel';
import { siteContext } from './SiteContextProvider';
import Link from '../components/layout/Link';

const shortcodes = { BluePanels, ServiceTemplatePanel, Link };

export const DefaultLayout: React.FC = ({ children }) => {
    const { active, setActive } = useContext(siteContext);
    return (
        <div id="layout">
            <GlobalStyles />
            <Nav active={active} setActive={setActive} />
            <div onClick={() => setActive(false)}>
                <MDXProvider components={shortcodes}>{children}</MDXProvider>
            </div>
            <VisitOurPlace />
            <Footer />
        </div>
    );
};

import { createContext, useState, FC, useContext } from 'react';

type SiteContext = {
    theme: string;
};

export const siteContext = createContext<SiteContext>({
    theme: 'default',
});

export const useLayoutContext = (): SiteContext => useContext(siteContext);

export const SiteContextProvider: FC = ({ children }) => {
    const [active, setActive] = useState(false);
    return <siteContext.Provider value={{ active, setActive }}>{children}</siteContext.Provider>;
};

import React from 'react';
import styled from '@emotion/styled';
import { ServicePanel } from '../../components/layout/Service-Panel';
import { StaticImage } from 'gatsby-plugin-image';
import { device } from '../layout/GlobalStyles';

const StyledServiceTemplatePanel = styled.div`
    margin-top: 36px;
    > div {
        margin-bottom: 32px;
    }
    @media ${device.tablet} {
        margin-top: 72px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }
`;

export const ServiceTemplatePanel: React.FC = ({ children, url, service }) => {
    return (
        <StyledServiceTemplatePanel className="container">
            <ServicePanel url={url} service={service}>
                {children}
            </ServicePanel>{' '}
        </StyledServiceTemplatePanel>
    );
};

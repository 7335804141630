import React from 'react';
import styled from '@emotion/styled';
// import { Link } from 'gatsby';
// import { StaticImage } from 'gatsby-plugin-image';
import { colors, device } from './GlobalStyles';
import arrowRight from '../../images/svgs/arrow-right-white.svg';
import { Link } from 'gatsby';

interface ComponentProps {
    classType?: string;
}

const StyledServicePanel = styled.div<ComponentProps>`
    position: relative;
    border-radius: 15px;
    z-index: 0;
    cursor: pointer;
    width: 345px;
    overflow: hidden;
    .shadow-filter {
        opacity: 1;
        border-radius: 15px;
        height: 240px;
        width: 345px;
        position: absolute;
        bottom: 0px;
        left: 0;
        z-index: 3;
        transition: opacity 0.4s cubic-bezier(0.33, 1, 0.68, 1);
        background: linear-gradient(
            360deg,
            rgba(0, 0, 0, 0.85) 0%,
            rgba(0, 0, 0, 0.2975) 42.55%,
            rgba(0, 0, 0, 0) 53.08%
        );
    }
    button {
        border: 2px solid ${colors.white};
        border-radius: 5px;
        background: none;
        padding: 15px;
        display: flex;
        align-items: center;
    }
    .flex-container {
        z-index: 9;
        bottom: -5px;
        align-items: start;
        justify-content: space-between;
        display: flex;
        width: 345px;
        margin: 0px 15px;
        position: absolute;
        p {
            transition: 0.2s cubic-bezier(0.33, 1, 0.68, 1);
            margin-top: 25px;
            color: ${colors.white};
            font-weight: 600;
            font-size: 20px;
            line-height: 132.5%;
            display: flex;
            align-items: flex-end;
            text-transform: capitalize;
        }
        button {
            img {
                transition: 0.2s cubic-bezier(0.33, 1, 0.68, 1);
            }
            position: absolute;
            right: 35px;
        }
    }
    .image-container {
        transition: 0.2s cubic-bezier(0.33, 1, 0.68, 1);
        width: 100%;
        overflow: hidden;
        z-index: -1;
        border-radius: 15px;
        img {
            z-index: -1;
            width: 345px;
            height: 200px;
            border-radius: 15px;
        }
    }
    :hover {
        .shadow-filter {
            opacity: 0.4;
            z-index: 3;
            background: linear-gradient(
                360deg,
                rgba(0, 0, 0, 0.85) 0%,
                rgba(0, 0, 0, 0.2975) 42.55%,
                rgba(0, 0, 0, 0) 53.08%
            );
        }
        button {
            img {
                transition: 0.2s cubic-bezier(0.33, 1, 0.68, 1);
                transform: scale(1.2);
            }
        }
        .image-container {
            transition: 0.2s cubic-bezier(0.33, 1, 0.68, 1);
            transform: scale(1.1);
        }
    }
    @media ${device.tablet} {
        width: 325px;
        .shadow-filter,
        .image-container,
        .flex-container {
            width: 325px;
        }
    }
    @media ${device.laptop} {
        width: 336.67px;
        .shadow-filter,
        .image-container,
        .flex-container {
            width: 336.67px;
        }
        .wide {
            width: 520px;
            div {
                img {
                    width: 520px;
                }
            }
        }
    }
    @media ${device.laptopL} {
        width: 386.67px;
        .flex-container {
            top: 210px;
        }
        .image-container {
            img {
                width: 386.67px;
                height: 280px;
            }
        }
        .shadow-filter,
        .image-container,
        .flex-container {
            width: 386.67px;
            height: 280px;
        }
        .wide {
            width: 590px;
            height: 280px;
            div {
                img {
                    height: 280px;
                    width: 590px;
                }
            }
        }
    }
`;

interface Props extends ComponentProps {
    children: JSX.Element;
    service: string;
    url: string;
}

export const ServicePanel: React.FC<Props> = ({ url, children, service, classType }) => {
    return (
        <StyledServicePanel className={`container ${classType}`}>
            <Link to={`${url}`}>
                <div className={`shadow-filter ${classType}`}></div>
                <div className={`image-container ${classType}`}>{children}</div>
                <div className={`flex-container ${classType}`}>
                    <p>{service}</p>
                    <button>
                        <img alt="see more" src={arrowRight} />
                    </button>
                </div>
            </Link>
        </StyledServicePanel>
    );
};

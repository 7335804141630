import React from 'react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { device, colors } from './layout/GlobalStyles';
import bingeEating from '../binge-eating.pdf';
import adhd from '../adhd.pdf';
import adhdAlt from '../adhd-questions.pdf';

const StyledBluePanels = styled.ul`
    list-style: none;
    li {
        margin: 0px 10px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1.5px solid #0597e9;
        border-radius: 15px;
        padding: 10px 00px;
        position: relative;
        height: 110px;
        width: 300px;
        a {
            color: ${colors.black};
            cursor: default;
            margin-top: 20px;
            text-align: center;
        }
        .name {
            width: 100%;
            height: 40px;

            margin-top: 15px;
            color: ${colors.black};
            font-weight: 400;
        }

        .dl {
            position: relative;
            bottom: 15px;

            cursor: pointer;
            &:hover {
                opacity: 0.7;
                text-decoration: underline;
            }
        }
        p {
            font-weight: 700;

            color: ${colors.blue2};
        }
        border: 1px solid ${colors.blue2};
    }
    @media ${device.tablet} {
        text-align: center;
        display: flex;
        justify-content: center;
        margin: 54px auto;
        width: 100%;
        li {
            margin: 10px 15px;
            width: 240px;
        }
    }
    @media ${device.tablet} {
        text-align: center;
        display: flex;
        justify-content: center;
        margin: 54px auto;
        width: 100%;
        li {
            margin: 10px 15px;
            width: 540px;
        }
    }
`;

interface Link {
    name: string;
    link: string;
}

export const BluePanels: React.FC<Link>[] = ({ links }) => {
    console.log(links);
    let urlName;

    return (
        <StyledBluePanels>
            {links.map((link: { name: string; url: string }) => {
                if (link.name === 'Binge Eating Disorder Screener-7') {
                    urlName = bingeEating;
                } else if (link.name === 'ADD') {
                    urlName = adhd;
                } else {
                    urlName = adhdAlt;
                }
                return (
                    <li key={link.name}>
                        <a href={urlName} target="_blank" rel="noopener noreferrer">
                            <p className="name">{link.name}</p>
                            <p className="dl">DOWNLOAD</p>
                        </a>
                    </li>
                );
            })}
        </StyledBluePanels>
    );
};

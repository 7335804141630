export const navMenu = [
    {
        name: 'About Us',
        path: '/about-us/',
        menu: [
            { name: 'Meet our Team', path: '/about-us/meet-our-team/' },
            { name: 'Testimonials', path: '/about-us/testimonials/' },
            { name: 'Intake Forms', path: '/about-us/intake-forms/' },
        ],
    },
    // {
    //     name: 'Addiction',
    //     path: '/our-services/addiction/',
    //     menu: [
    //         {
    //             name: 'Alcoholism Treatment',
    //             path: '/addiction/alcoholism/',
    //         },
    //         {
    //             name: 'Opiate Addiction',
    //             path: '/addiction/opiate-addiction/',
    //         },
    //     ],
    // },
    {
        name: 'Pain',
        path: '/pain/',
    },
    {
        name: 'Female Hormones',
        path: '/services/treatments/female-hormones/',
    },
    {
        name: 'Testosterone',
        path: '/services/treatments/testosterone/',
    },
    {
        name: 'Weight Loss',
        path: '/services/treatments/weight-loss/',
    },
    {
        name: 'Energy',
        path: '/services/treatments/energy-get-more-of-it/',
        menu: [
            {
                name: 'Energy Pills',
                path: '/therapies/energy-pills/',
            },
        ],
    },
    {
        name: 'Thyroid',
        path: '/services/hyperthyroidism/',
        menu: [
            {
                name: 'Thyroid PX Instructions',
                path: '/therapies/thyroid-px-instructions/',
            },
        ],
    },
    {
        name: 'Blog',
        path: '/blog/',
        menu: [
            {
                name: 'What Are the Signs you Need Estrogen Therapy?',
                path: '/blog/signs-estrogen',
            },
            {
                name: 'When Should You See a Thyroid Doctor?',
                path: '/blog/when-should-you-see-thyroid-doctor/',
            },
        ],
    },
    {
        name: 'Conditions',
        path: '/conditions/',
    },
    {
        name: 'Counseling',
        path: '/counseling/',
    },
    {
        name: 'Therapies',
        path: '/therapies/',
    },
    {
        name: 'Anti-Aging Medicine',
        path: '/antiaging-medicine/',
    },
    // {
    //     name: 'Slow Metabolism',
    //     path: '/slow-metabolism/',
    // },
    {
        name: 'Supplements',
        path: '/supplements/',
    },
    // { name: 'Growth Hormone', path: '/growth-hormone/' },
    // {
    //     name: 'Sleep Treatment',
    //     path: '/services/treatments/sleep/',
    // },
    // {
    //     name: 'Therapies',
    //     path: '/therapies/',
    // },
];

export const mobileNavMenu = [
    {
        name: 'About Us',
        path: '/about-us/',
        menu: [
            { name: 'Meet our Team', path: '/about-us/meet-our-team/' },
            { name: 'Testimonials', path: '/about-us/testimonials/' },
            { name: 'Intake Forms', path: '/about-us/intake-forms/' },
        ],
    },
    {
        name: 'Our Services',
        path: '/our-services/',
        menu: [
            { name: 'Antiaging Medicine', path: ' /antiaging-medicine/' },
            { name: 'Energy Boost', path: '/services/treatments/energy-get-more-of-it/' },
            { name: 'Energy Pills', path: '/therapies/energy-pills/' },
            {
                name: 'Female Hormones',
                path: '/services/treatments/female-hormones/',
            },
            // { name: 'Growth Hormone', path: '/growth-hormone/' },
            { name: 'Pain Management', path: '/pain/' },
            // { name: 'Slow Metabolism', path: '/slow-metabolism/' },
            { name: 'Supplements', path: '/supplements/' },
            // { name: 'Sleep Treatment', path: '/services/treatments/sleep/' },
            {
                name: 'Testosterone',
                path: '/services/treatments/testosterone/',
            },

            {
                name: 'Thyroid',
                path: '/services/hyperthyroidism/',
            },
            {
                name: 'Thyroid PX Instructions',
                path: '/therapies/thyroid-px-instructions/',
            },
            {
                name: 'Weight Loss',
                path: '/services/treatments/weight-loss/',
            },
        ],
    },

    {
        name: 'Conditions',
        path: '/conditions/',
    },
    {
        name: 'Counseling',
        path: '/counseling/',
    },
    {
        name: 'Therapies',
        path: '/therapies/',
    },
    {
        name: 'Blog',
        path: '/blog/',
        menu: [
            {
                name: 'What Are the Signs you Need Estrogen Therapy?',
                path: '/blog/signs-estrogen',
            },
            {
                name: 'When Should You See a Thyroid Doctor?',
                path: '/blog/when-should-you-see-thyroid-doctor/',
            },
        ],
    },
];

import { colors } from './GlobalStyles';
import styled from '@emotion/styled';

interface Props {
    button: string;
    fill?: boolean;
}

export const Button = styled.button<Props>`
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    width: 313px;
    line-height: 27px;
    text-align: center;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    height: 56px;
    align-items: center;
    border-radius: 5px;
    transition: 0.2s cubic-bezier(0.33, 1, 0.68, 1);
    background: ${({ button, fill }) => button === 'primary' && fill && `${colors.black}`};
    color: ${({ button }) => button === 'primary' && `${colors.white}`};
    color: ${({ button, fill }) => button === 'secondary' && !fill && `#0597E9`};
    border: ${({ button, fill }) => button === 'primary' && fill && `2px solid ${colors.black}`};
    background: ${({ button, fill }) => button === 'secondary' && fill && `#0597E9`};
    background: ${({ button, fill }) => button === 'secondary' && !fill && `none`};
    color: ${({ button, fill }) => button === 'secondary' && fill && `${colors.white}`};
    border: ${({ button }) => button === 'secondary' && `2px solid #0597E9`};
    img {
        position: relative;
        right: 15px;
    }
    span {
        position: relative;
        left: 8px;
    }
    :disabled {
        background: ${({ button, fill }) => button === 'secondary' && !fill && `none`};
        border: ${({ button, fill }) =>
            button === 'secondary' && !fill && `2px solid ${colors.gray4}`};
        color: ${({ button, fill }) => button === 'secondary' && !fill && `${colors.gray4}`};
        background: ${({ button }) => button === 'secondary' && `${colors.white}`};
        border: ${({ button }) => button === 'secondary' && `2px solid ${colors.gray4}`};
        color: ${({ button }) => button === 'secondary' && `${colors.gray4}`};
    }
    &:hover {
        background: ${({ button, fill }) =>
            button === 'secondary' && !fill && `rgba(112, 201, 251, 0.2)`};
        background: ${({ button, fill }) => button === 'primary' && fill && `#525657`};
        border: ${({ button, fill }) => button === 'primary' && fill && `2px solid #525657`};
        background: ${({ button, fill }) => button === 'secondary' && fill && `#0E79B5`};
        border: ${({ button, fill }) => button === 'secondary' && fill && `2px solid #0E79B5`};
    }
`;
